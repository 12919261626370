<template>
    <BT-Blade-Item
        bladeName="receival"
        :bladesData="bladesData"
        :canDelete="false"
        :canEdit="false"
        :loadingMsg="loadingMsg"
        navigation="receivals"
        :refreshToggle="refreshToggle"
        title="Receival">
        <template v-slot="{ item }">
            <v-row no-gutters>
                <BT-Snack v-model="msg" />

                <v-col cols="12" sm="6" class="my-auto">
                    <BT-Field-Company
                        :id="item.sellerID"
                        label="SENDER"
                        :title="item.seller.companyName" />
                </v-col>
                
                <v-col cols="12" sm="6" class="my-auto">
                    <BT-Field-Company
                        :id="item.courierID"
                        label="COURIER"
                        :title="item.courier.companyName" />
                </v-col>
                
                <v-col cols="6" sm="4" class="d-flex align-center">
                    <BT-Field-Address
                        v-if="!item.isPickup"
                        label="DELIVER TO"
                        :value="item.destinationLocation" />
                    <BT-Field-Address
                        v-else
                        label="PICKUP FROM"
                        :value="item.departureLocation" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        label="Due Arrival On"
                        shortDateAndTime
                        v-model="item.dueArrivalOn" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        v-if="item.releasedOn != null" 
                        label="Released On"
                        v-model="item.releasedOn"
                        shortDateAndTime />
                </v-col>

                <v-col cols="12">
                    <v-list-item dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Status</v-list-item-subtitle>
                            <v-list-item-title v-if="item.isFulfilled">Completed</v-list-item-title>
                            <v-list-item-title v-else-if="!item.isPickup && item.deliveredOn != null">Delivered</v-list-item-title>
                            <v-list-item-title v-else-if="item.releasedOn != null">{{ item.isPickup ? 'Picked Up' : 'Released' }}</v-list-item-title>
                            <v-list-item-title v-else-if="item.isPickup">Ready</v-list-item-title>
                            <v-list-item-title v-else>Pending</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>

                <v-col v-if="item.deliveredOn != null" cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-draw</v-icon>
                        Proof Of Delivery
                        <v-spacer />
                        <span>Signer: {{ item.receiverName || '(Unknown)' }}</span>
                    </v-subheader>
                    <BT-Signature
                        class="ml-4 mb-1"
                        height="150px"
                        v-model="item.proofOfDelivery" />
                    <span class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            navigation="public-users"
                            inline
                            :itemValue="item.deliveredByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                        <span>at {{ item.deliveredOn | toShortDateAndTime }}</span>
                    </span>
                </v-col>
                
                <v-col v-if="item.deliveredOn != null" cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-camera</v-icon>
                        Photo
                        <v-spacer />
                        <BT-Btn
                            v-if="item.lat != null && item.lng != null"
                            :href="`https://maps.google.com/?q=${item.lat},${item.lng}`"
                            inline
                            label="Check Map"
                            leftIcon="mdi-map-marker-radius"
                            small
                            target="_blank" />
                    </v-subheader>
                    <BT-Photo
                        height="150px"
                        :icon="null"
                        :id="item.id" />
                </v-col>

                <v-col v-if="item.receivedByUserID == null" cols="12" sm="4">
                    <v-divider class="my-2" />
                    <BT-Btn
                        block
                        class="primary"
                        @click="receive(item)"
                        :disabled="item.deliveredOn == null || item.isPickup && item.releasedOn == null"
                        height="75%"
                        icon="mdi-clipboard-arrow-down-outline"
                        :label="isReadyToReceive ? 'Receive' : 'Count Received Packages'" />
                </v-col>
                <v-col v-else cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>Received</v-subheader>
                    <span class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            navigation="public-users"
                            inline
                            :itemValue="item.receivedByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                    </span>
                </v-col>

                <v-col cols="12">
                    <BT-List v-if="isReadyToReceive"
                        :items="item.packagedItems">
                        <template v-slot:listItem="{ item }">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.measurementStandard }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <BT-Number-Edit
                                        v-model="item.quantityReceived"
                                        label="Received"
                                        :suffix="'/' + item.quantityDelivered" />
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </BT-List>
                    <BT-Table
                        v-else
                        :canRefresh="false"
                        class="mt-4"
                        :headers="[
                            { text: 'Packages', value: 'measurement', display: true, title: 1 },
                            { text: 'Expected', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' },
                            { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' },
                            { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Received: ' }]"
                        hideActions
                        :items="item.packagedItems">
                        <template v-slot:measurement="{ item }">
                            <BT-Entity 
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName"
                                single
                                useLocalCache />
                        </template>
                        <template v-slot:listItem="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <BT-Entity
                                        navigation="measurements"
                                        :itemValue="item.measurementID"
                                        itemText="measurementName"
                                        single
                                        useLocalCache />
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Packed: {{ item.quantityPacked | toDisplayNumber }} | Released: {{ item.quantityReleased | toDisplayNumber }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Table>
                </v-col>
            </v-row>
            
            <!-- <BT-Packages-Movement-Dialog
                v-if="item.receivedByUserID == null"
                v-model="item.packagedItems"
                label="Edit Packages Received"
                received
                small
                @ok="packages => { item.packagedItems = packages }" />

            <v-expansion-panels>
                <BT-Blade-Expansion-Items
                    :canExportCSV="false"
                    :canRefresh="false"
                    :canSelect="false"
                    :headers="[
                        { text: 'Package', value: 'measurement', display: true, title: 1 },
                        { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' },
                        { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Received: ' }]"
                    hideActions
                    :items="item.packagedItems"
                    title="Packages">
                    <template v-slot:measurement="{ item }">
                        <span v-if="item.measurementStandard != null">{{ item.measurementStandard }}</span>
                        <BT-Entity
                            v-else
                            navigation="measurements"
                            :itemValue="item.measurementID"
                            itemText="measurementName" />
                    </template>
                    <template v-slot:listItem="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>
                                <span v-if="item.measurementStandard != null">{{ item.measurementStandard }}</span>
                                <BT-Entity
                                    v-else
                                    navigation="measurements"
                                    :itemValue="item.measurementID"
                                    itemText="measurementName" />
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Delivered: {{ item.quantityDelivered | toDisplayNumber }} | Received: {{ item.quantityReceived | toDisplayNumber }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </BT-Blade-Expansion-Items>
            </v-expansion-panels> -->
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Receival-Blade',
    components: {
        // BTPackagesMovementDialog: () => import('~components/BT-Packages-Movement-Dialog.vue'),
        BTPhoto: () => import('~components/BT-Photo.vue'),
        BTSignature: () => import('~components/BT-Signature.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            isReadyToReceive: false,
            loadingMsg: null,
            msg: null,
            refreshToggle: false,
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async receive(item) {
            if (!this.isReadyToReceive) {
                this.isReadyToReceive = true;
            }
            else {
                //save
                try {
                    this.loadingMsg = 'Receiving';
                    var res = await this.$BlitzIt.store.patch('receivals', item);
                    item.rowVersion = res.rowVersion;
                    this.$BlitzIt.navigation.navBack();
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        }
    }
}
</script>